import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Attachment, AttachmentInfo } from '@core/models/interfaces/attachment';
import { ResponseApi } from '@core/models/interfaces/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private baseUrl = `${environment.API_URL}/storage`;

  constructor(private httpClient: HttpClient) {}

  uploadFile(file: File) {
    const data = new FormData();
    data.append('file', file);

    return this.httpClient.post<ResponseApi<Attachment>>(`${this.baseUrl}/files/upload-file`, data);
  }

  uploadFileWithProgress(file: File) {
    const data = new FormData();
    data.append('file', file);

    return this.httpClient.post<ResponseApi<Attachment>>(`${this.baseUrl}/files/upload-file`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getFile(filePath: string) {
    return this.httpClient.get(`${this.baseUrl}/files${filePath}`, {
      responseType: 'blob'
    });
  }

  getFileUrl(filePath: string) {
    return `${this.baseUrl}/files${filePath}`;
  }

  getFileInfo(filePath: string) {
    return this.httpClient.get<ResponseApi<AttachmentInfo>>(`${this.baseUrl}/files/info${filePath}`);
  }
}

<div [ngClass]="[styleClass]">
  <img
    loading="lazy"
    [alt]="alt"
    class="w-full h-full object-cover object-center opacity-0 transition-opacity duration-500 ease-in-out"
    [ngClass]="[imageLoaded ? 'opacity-100' : '', imageClass]"
    [src]="imageUrl"
    (load)="onImageLoad()"
    (error)="onError()" />
</div>
